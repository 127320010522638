import React from "react"
import SEO from "../components/Seo"
import Icon from "../components/Icon"
import Layout from "../components/Layout"
import MultiVideoPlayer from "../components/MultiVideoPlayer"
import Resource from "../components/Resource"
import Section from "../components/Section"
import Button from "../components/Button"
import Image from "../components/Image"
import { ImageVideoModal } from "../components/Modal"
import TheCatchLogo from "../images/theme/logo-the-catch.svg"
import FcMadeline from "../images/theme/img-featured-contributor-madeline.jpg"
import FcRithika from "../images/theme/img-featured-contributor-rithika.jpg"
import FcRobin from "../images/theme/img-featured-contributor-robin.jpg"
import FcVictoria from "../images/theme/img-featured-contributor-victoria.jpg"
import { Container, Row, Column } from "../components/Grid"

const VFTPage = () => {
  return (
    <Layout>
      <SEO title="Heroes" />

      {/***************  HERO ***************/}
      <Section className="hero">
        <Container>
          <h1>Be Vape Free: Heroes Virtual Field Trip</h1>
          <Row>
            <Column span={7}>
              <h3 className="hero__subhead">
                Join us for a real-world virtual learning opportunity to get the
                facts on the vaping epidemic.
              </h3>
            </Column>
          </Row>
        </Container>
      </Section>

      {/******************** BODY *********************/}

      <Section>
        <Container>
          <h2 class="serif">Be Vape Free: Heroes Virtual Field Trip</h2>
          <Row className="mb-1 mt-1">
            <Column>
            <ImageVideoModal
              imgPlaceholder="theme/posters/heroes/CVS-BVF-2022-VFT-Heroes-Intro.jpg"
              src="https://catch-public.s3.us-east-2.amazonaws.com/programs/CATCH-My-Breath/videos/virtual-field-trip-heroes.mp4"
            />
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <h2>Real Heroes Busting Vape Myths</h2>
              <p>Give students the skills to say “No” to risky behaviors like vaping and empower healthy
                decision-making with this anti-vaping adventure. Meet diverse subject matter experts and
                teenage advocates who are harnessing their knowledge of the harmful effects of e-cigarettes
                to make a positive impact locally and beyond. Students will go inside the world of social
                media to explore how their decision-making regarding e-cigarettes can be influenced by
                others, while discovering strategies to avoid unhealthy behaviors. Inspire a new generation
                of health heroes by equipping students with tools they can use to help those who are addicted
                find the courage and support to quit.</p>
            </Column>
            <Column span={6}>
              <div className="socialcard">
                <div className="socialcard__left">
                  <Icon name="twitter" />
                </div>
                <div className="socialcard__right">
                  <h2>#BeVapeFree</h2>
                  <a
                    href="https://twitter.com/hashtag/BeVapeFree"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialcard__right-link"
                  >
                    View conversation on Twitter
                    <Icon name="externallink" />
                  </a>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>

      {/** <Section>
        <Container>
          <h2 class="serif">Be Vape Free: Heroes Virtual Field Trip </h2>
          <Row className="mb-1 mt-1">
            <Column>
              <ImageVideoModal
                imgPlaceholder="img-vft-poster-2022.jpg"
                guidSrc="cc7c8f47-d2ee-49bd-a4c0-81b4f44b21ad"
              />
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <h2>Real Heroes Busting Vape Myths</h2>
              <p>Give students the skills to say “No” to risky behaviors like vaping and empower healthy
                decision-making with this anti-vaping adventure. Meet diverse subject matter experts and
                teenage advocates who are harnessing their knowledge of the harmful effects of e-cigarettes
                to make a positive impact locally and beyond. Students will go inside the world of social
                media to explore how their decision-making regarding e-cigarettes can be influenced by
                others, while discovering strategies to avoid unhealthy behaviors. Inspire a new generation
                of health heroes by equipping students with tools they can use to help those who are addicted
                find the courage and support to quit.</p>
            </Column>
            <Column span={6}>
              <div className="socialcard">
                <div className="socialcard__left">
                  <Icon name="twitter" />
                </div>
                <div className="socialcard__right">
                  <h2>#BeVapeFree</h2>
                  <a
                    href="https://twitter.com/hashtag/BeVapeFree"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialcard__right-link"
                  >
                    View conversation on Twitter
                    <Icon name="externallink" />
                  </a>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section> **/}

      {/**********************  Educational Guide **************************/}

      <Section className="yellow-pattern">
        <Container>
          <Row className="mb-1">
            <h2>Heroes: A Be Vape Free VFT Educator Guide</h2>
          </Row>
        </Container>
        <Resource
          image="img-vft-educator-guide-v2.jpg"
          title="Virtual Field Trip Educator Guide"
          description={
            <>
              <p>
                Introduce students to the influencers in their own lives and have them consider who and
                what influences their own personal health decisions using this companion guide. Find ways to
                connect important concepts to students’ everyday lives using key vocabulary, learning
                objectives, and activities for before and after watching the Virtual Field Trip.
              </p>
            </>
          }
          actions={[
            {
              label: "Educator Guide PDF",
              path: "/resources/CVS-CATCH-Heroes-VFT-EdGuide.pdf",
              type: "download",
            },
          ]}
          horizontal
        />
      </Section>

      {/***************  FEATURED CONTRIBUTORS ***************/}
      <Section className="featured-contributors">
        <Container>
          <Row className="pb-2">
            <Column span={9}>
              <h3>Featured Contributors</h3>
              <p>Meet the heroes courageously taking a stand against vaping, becoming prevention advocates,
                educating themselves, their classmates and younger kids and making a difference in their
                communities. </p>
            </Column>
          </Row>
          <Row>
            <Column span={3} className="pr-1">
              <img
                src={FcMadeline}
                alt="Madeline"
                className="featured-contributor__image"
              />
              <h3>Madeline</h3>
              <h4>Biloxi High School
                <br />Biloxi, MS</h4>
              <p>“I think the most important way [to make a difference] is to talk to people in your life.
                You don't have to be an advocate for a national organization to make a difference. It can
                just be one person, you know, helping them, or just talking to one person about
                vaping prevention.”</p>
            </Column>
            <Column span={3} className="pl-1 pr-1">
              <img
                src={FcRithika}
                alt="Rithika"
                className="featured-contributor__image"
              />
              <h3>Rithika</h3>
              <h4>Cumberland Valley High School
                <br />
                Mechanicsburg, PA </h4>
              <p>“Advocacy is important because it changes the popular beliefs, and it changes opinions that
                are already predisposed.  It changes preconceived notions about whatever it is. It could be
                about vaping. It could be about addiction or anything really.”</p>
            </Column>
            <Column span={3} className="pl-1 pr-1">
              <img
                src={FcRobin}
                alt="Robin"
                className="featured-contributor__image"
              />
              <h3>Robin</h3>
              <h4>Sierra Valley High School
                <br />
                Los Angeles, CA </h4>
              <p>“I would like to invite everyone to take a stand like I did. Taking on vaping prevention
                is a long and laborious process and requires the effort of friends, family, and even
                teachers. It is a great honor to see someone get out of the clutches of vaping through
                your help.”</p>
            </Column>
            <Column span={3} className="pl-1">
              <img
                src={FcVictoria}
                alt="Victoria"
                className="featured-contributor__image"
              />
              <h3>Victoria</h3>
              <h4>North Allegheny Senior High School
                <br />
                Pittsburgh, PA </h4>
              <p>“My biggest piece of advice for anyone who wants to get involved is i think there’s no one
                right way to create change or do something. Think about what you like doing, what you’re
                good at, how that intersection kind of forms and then act on that. It doesn’t always have to be the
                organizing, leading, or rallying, that we often see.”</p>
            </Column>
          </Row>
        </Container>
      </Section>
      {/***************  VFT Survey ***************/}
      <Section className="homepage__callout">
        <Container>
          <a
            href="https://surveys.discoveryeducation.com/s3/CVS-CATCH-Be-Vape-Free-Heroes-Virtual-Field-Trip-Survey-Short"
            target="_blank"
            rel="noopener noreferrer"
            className="cta"
          >
            Watched the Virtual Field Trip? Share your feedback with us!
            <Icon name="rightarrow" />
          </a>
        </Container>
      </Section>

      {/***************  CATCH MY BREATH CALLOUT ***************/}
      <Section className="lightgray vftboilerplate">
        <Container>
          <Row>
            <Column span={5}>
              <img
                src={TheCatchLogo}
                alt="The CATCH"
                className="vftboilerplate__image"
              />
            </Column>
            <Column span={7}>
              <p>
                Access the Core Program Bundle to complete the{" "}
                <strong>CATCH My Breath</strong> program. Using evidence-based
                and standards-aligned material, the Core Program Bundle teaches
                elementary, middle, and high school students the dangers of
                vaping.
              </p>
              <p>
                <a href="/educators" className="global__button">
                  Get Access
                </a>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>

    </Layout>
  )
}

export default VFTPage
