import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "gatsby";
import "./styles.scss";

const Button = ({ href, className, inverse, children, ...rest }) => {
  const getClassnames = () =>
    classNames("button", inverse && "button--inverse", className);

  if (href) {
    if (href.indexOf("http") === 0 || href.indexOf("#") === 0) {
      return (
        <a href={href} className={getClassnames()} {...rest}>
          {children}
        </a>
      );
    }
    return (
      <Link to={href} className={getClassnames()} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <button className={getClassnames()} {...rest}>
      {children}
    </button>
  );
};

Button.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  inverse: PropTypes.bool
};

export default Button;
